<template>
  <div v-loading="pageLoading">
    <div class="top_div" @click="goBack">
      <i class="el-icon-arrow-left left_link"></i>测评
    </div>
    <div class="pro">
      <el-progress :percentage="percentage || 0" :stroke-width="30">
        <el-button type="text">
          {{ current_index + 1 }}/{{ has_choose_ans.length }}
        </el-button>
      </el-progress>
    </div>
    <div class="card" v-loading="saveLoading">
      <div class="ans_title">{{ current_title }}</div>
      <div>
        <div
          v-for="(item, key) in detail.ans"
          :key="key"
          class="ans_item"
          :class="{
            ans_item_choose: has_choose_ans[current_index] == item.code,
          }"
          @click="choose_ans(key, item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail } from "@/api/wenjuan";
import { create } from "@/api/wenjuanResult";

export default {
  name: "StepAns",
  data() {
    return {
      uid: null,
      type: null,
      wenjuan_id: null,
      current_index: 0,
      current_title: "",
      detail: {
        ans: [],
        detail: [],
      },
      has_choose_ans: [],
      pageLoading: false,
      saveLoading: false, //保存答案中
    };
  },
  mounted() {
    //没有uid就进去选择病人
    if (this.$route.query.uid) {
      this.uid = this.$route.query.uid;
    } else {
      this.go_choose_patient();
    }
    //没有问卷id就去选择对应的问卷
    if (this.$route.query.wenjuan_id) {
      this.wenjuan_id = this.$route.query.wenjuan_id;
    } else {
      this.go_index();
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.fetchDetail();
  },
  computed: {
    percentage() {
      return parseInt(
        ((parseInt(this.current_index) + 1) / this.has_choose_ans.length) * 100
      );
    },
  },
  methods: {
    async choose_ans(key, item) {
      let that = this;
      // if (this.has_choose_ans[this.current_index] != "") {
      //   return;
      // }
      this.has_choose_ans[this.current_index] = item.code;
      if (item.code == "") {
        return;
      }
      this.$forceUpdate();
      setTimeout(async () => {
        if (this.has_choose_ans[this.current_index] == "") {
          return;
        }
        if (that.current_index < that.has_choose_ans.length - 1) {
          that.current_index = that.current_index + 1;
          that.current_title = that.detail.detail[that.current_index].title;
        } else {
          that.saveResult();
        }
      }, 200);
    },
    //保存答题结果，并跳转页面
    async saveResult() {
      let that = this;
      if (this.saveLoading) {
        return;
      }
      this.saveLoading = true;
      let saveData = {
        wenjuan_id: this.wenjuan_id,
        wenjuan_identify: this.type,
        patient_id: this.uid,
        ans_detail: this.has_choose_ans,
      };
      try {
        const { data } = await create(saveData);
        await that.$router.push({
          path: "report_create",
          query: { id: data.id },
        });
      } catch (error) {
        console.log(error);
      } finally {
        that.saveLoading = false;
      }
    },
    //跳转至选择病人页面
    go_choose_patient() {
      this.$router.push({
        path: "choose_patient",
      });
    },
    //跳转至选择问卷页面
    go_index() {
      this.$router.push({
        path: "/",
        query: { uid: this.uid },
      });
    },
    //点击顶部的返回按钮
    goBack() {
      let that = this;
      if (that.current_index == 0) {
        this.$confirm("返回后需要重新做题，确定要返回吗?", "提示", {
          confirmButtonText: "确定返回",
          cancelButtonText: "再想一想",
          type: "warning",
          buttonSize: "large",
        })
          .then(() => {
            that.go_index();
          })
          .catch(() => {});
      } else {
        that.current_index = that.current_index - 1;
        that.current_title = that.detail.detail[that.current_index].title;
      }
    },
    //获取数据详情
    async fetchDetail() {
      try {
        this.pageLoading = true;
        const { data } = await getDetail(this.wenjuan_id);
        this.detail.ans = data.ans;
        this.detail.detail = data.detail;
        this.current_index = 0;
        this.current_title = this.detail.detail[this.current_index].title;
        data.detail.forEach(() => {
          this.has_choose_ans.push("");
        });
      } finally {
        this.pageLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top_div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 131px;
  font-size: 48px;
  font-weight: bold;
  color: #101010;
  background-color: #fff;
}
.left_link {
  margin-left: 15px;
}
.pro {
  padding: 30px;
}
.card {
  width: 676px;
  padding: 72px 58px;
  margin: 0px auto;
  margin-top: 89px;
  font-size: 42px;
  background: #ffffff;
  border-radius: 50px;
  box-shadow: 0px 0px 18px 0px rgba(7, 133, 153, 0.06);
}
.ans_title {
  margin-bottom: 92px;
}
.ans_item {
  width: 641px;
  height: 102px;
  margin: 0px auto;
  margin-top: 36px;
  font-size: 36px;
  font-weight: bold;
  line-height: 102px;
  color: #606266;
  text-align: center;
  cursor: pointer;
  background: #f2f2f2;
  border-radius: 51px;
}
.ans_item_choose {
  color: #fff;
  background-color: #078599;
}
</style>
